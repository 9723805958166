import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { Collapse } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import ContentWrapper from "../components/contentWrapper"

function TeamPage() {
  const [showCurriculum, setShowCurriculum] = useState(false);

  function handleCurriculumToggleButton() {
    setShowCurriculum(current => !current);
  }

  return (
    <ContentWrapper title='Team'>
      <Row>
        <Col md={{ span: 5, offset: 2 }}>
          <img src="/DrGuschelbauerLerch02.jpg" className="rounded img-fluid" alt='Portrait Dr. Guschelbauer-Lerch'/>
          <h4>Dr. Karin Guschelbauer-Lerch
            <small className='text-muted'><br />
              Fachärztin für Psychiatrie<br />
              Ärztin für Allgemeinmedizin
            </small>
          </h4>
          <h5>Ärztekammerdiplome</h5>
          <ul>
            <li>Notarztdiplom</li>
            <li>ÄK Diplom f. Psychosoziale Medizin</li>
            <li>ÄK Diplom f. Psychosomatik</li>
            <li>ÄK Diplom für Akupunktur (inkl. Diplom zur NADA Therapeutin)</li>
            <li>ÄK Diplom f. Geriatrie</li>
            <li>ÄK Diplom f. Palliativmedizin</li>
          </ul>
          <h5>Fremdsprachen</h5>
          <ul>
            <li>Englisch</li>
            <li>Französisch</li>
            <li>Italienisch (Klinikdolmetscherin)</li>
          </ul>
          <Button variant="outline-primary" onClick={handleCurriculumToggleButton}>Curriculum</Button>
          <Collapse in={showCurriculum}>
            <div className="well">
              <ul>
                <li>Matura am Gymnasium der Franziskaner in Hall i. Tirol</li>
                <li>Medizinstudium an der Leopold Franzens Universität Innsbruck</li>
                <li>Famulaturen an der Univ. Klinik Innsbruck und am Ospedale civile San Dona`di Piave (Italien)</li>
                <li>Promotion zur Doktorin der gesamten Heilkunde an der Leopold Franzens Universität Innsbruck</li>
                <li>Turnusärztin an der Universitätsklinik Innsbruck, an den Landeskrankenhäusern Hall i.T. und Hochzirl sowie
                  in der Ordination Dr. Böser in Innsbruck
                </li>
                <li>ÖÄK Prüfung Arzt für Allgemeinmedizin in Innsbruck</li>
                <li>Assistenzärztin am LKH Hall i.T. Abteilung f. Psychiatrie sowie LKH Hochzirl Abt. f. Neurologie</li>
                <li>ÖÄK Facharztprüfung Psychiatrie in Wien</li>
                <li>Tiefenpsychologisch fundierte Psychotherapie in Ausbildung</li>
                <li>Oberärztin am LKH Hall i.T. Abteilung für Psychiatrie A</li>
                <li>Seit 01.04.2015 in freier Praxis tätig</li>
              </ul>
            </div>
          </Collapse>
          <hr />
          <img src="/CamillaAccardo.jpg" className="rounded img-fluid" alt='Camilla Accardo'/>
          <h4>Camilla Accardo
            <small className='text-muted'><br />
              Empfang und Administration
            </small>
          </h4>
          <hr />
          <img src="/TBrandstaetter.jpeg" className="rounded img-fluid" alt='Theresa Brandstätter'/>
          <h4>Theresa Brandstätter
            <small className='text-muted'><br />
              Empfang und Administration
            </small>
          </h4>
          <hr />
          <img src="/BGerhold.jpg" className="rounded img-fluid" alt='Portrait Mag. Brigitte Gerhold'/>
          <h4>Mag. Brigitte Gerhold
            <small className='text-muted'><br />
              Klinische und Gesundheitspsychologin<br />
              Psychotherapeutin (Katathym Imaginative Psychotherapie)<br />
              Coaching
            </small>
          </h4>
          <hr />
          <img src="/LSanter.jpeg" className="rounded img-fluid" alt='Lydia Santer'/>
          <h4>Lydia Santer
            <small className='text-muted'><br />
              Shiatsu Praktikerin
            </small>
          </h4>
          <hr />
          <h4>Mag. Simona Agnoli
            <small className='text-muted'><br />
              Psychotherapeutin (Psychoanalyse)
            </small>
          </h4>
        </Col>
      </Row>
    </ContentWrapper>
  );
}

export default TeamPage;
